@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


:root {
    --brand-blue: #00abcc;
    --brand-green: #8bc53f;
    --brand-orange: #f9a51a;
    --brand-yellow: #ffdd00;
}
/*
#00abcc - blue
#8bc53f - green
#f9a51a - orange
#ffdd00 - yellow
*/

// copied from tailwind
$breakpoints: (
'sm':  640px,
'md': 768px,
'lg':  1024px,
'xl':  1280px,
'2xl':  1536px
);

.App {
    /* text-align: center; */
}

.App-logo {
    /* height: 40vmin; */
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        /* animation: App-logo-spin infinite 20s linear; */
    }
}

/*
.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
*/

/*
|--------------------------------------------------------------------------
| Sidebar override
|--------------------------------------------------------------------------
*/
$sidebar-bg-color: #fff;
$highlight-color: #fff;
$breakpoint-md: 768px; // for sidebar

@import '~react-pro-sidebar/dist/scss/styles.scss';

.pro-sidebar {
    height: 100vh;
    position: sticky;
    top: 0;
}
//        <aside className="min-h-screen sticky top-0 flex-initial basis-1/5 shrink-0 bg-white shadow-md flex flex-col">

.sidebar-toggle-btn {
    cursor: pointer;
    /*
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    */
    display: none;
}

.sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
        transition: width 0.3s;
        width: 150px;
        padding: 1px 15px;
        border-radius: 40px;
        background: rgba(255, 255, 255, 0.05);
        color: #adadad;
        text-decoration: none;
        margin: 0 auto;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-overflow: ellipsis;
        overflow: hidden;
        span {
            margin-left: 5px;
            font-size: 13px;
        }
        &:hover {
            color: $highlight-color;
        }
    }
}

.pro-inner-item {
    padding: 0 !important;
}

@media (max-width: $breakpoint-md) {
    .sidebar-toggle-btn {
        display: flex;
    }
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    @apply text-gray-900;
}

.pro-menu-item.active {
    @apply border-orange text-gray-900 #{!important};// bg-green-500/100 text-white;
}
.pro-icon-wrapper {
    .pro-icon {
        animation: none !important;
    }
}

// make link work for entire cell
.pro-menu-item {
    position: relative;
    > .pro-inner-item {
        position: unset !important;
    }
}

.pro-sidebar > .pro-sidebar-inner {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 331.94 414.58'%3E%3Cpath d='m155.69,145.06L.94,271.27V37.58l154.74,107.48Z' fill='%23f9a51a'/%3E%3Cpath d='m331.94,0L0,272.09v142.49h331.94V0Z' fill='%23fd0'/%3E%3C/svg%3E");
    background-size: 102% auto; // 102% to fix subpixel aliasing
    background-position: bottom center;
    background-repeat: no-repeat;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
    border-bottom: 0;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
    border-top: 0;
}

/*
nav.pro-menu {
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(10px);
}
*/

/*
|--------------------------------------------------------------------------
| Lines
|--------------------------------------------------------------------------
*/
.has-line {
    --line-width: 3px;
    --line-circle-size: calc(var(--line-width) * 4);
    --line-box-margin: 0.5rem; // mb-2
    --line-box-padding: 1.5rem; // py-6

    position: relative;

    + .has-line {

        &:before, &:after {
            content: '';
            position: absolute;
            z-index: 200;
            display: block;
            background: var(--brand-green);
        }


        &:before {
            width: var(--line-width);
            height: calc(var(--line-box-margin) + var(--line-box-padding) - (var(--line-circle-size) / 2));
            top: calc(var(--line-box-margin) * -1);
            left: calc((1.5rem - var(--line-width)) / 2);
        }

        &:after {
            width: var(--line-circle-size);
            height: var(--line-circle-size);
            top: calc(var(--line-box-padding) - (var(--line-circle-size) / 2));
            left: calc((var(--line-box-padding) - var(--line-circle-size)) / 2);
            border-radius: 100%;
        }
    }
}

.mb-6 + .has-line {
    --line-box-margin: 1.5rem;
}

/*
|--------------------------------------------------------------------------
| Rich Text
|--------------------------------------------------------------------------
*/
.rich-text-container {
    p {
        margin-bottom: 1rem;
    }

    > :last-child {
        margin-bottom: 0;
    }

    ol, ul {
        @apply list-outside;
        margin-left: 20px;
    }

    > ol,
    > ul {
        @apply my-3 max-w-4xl;

        p {
            margin: 0;
        }

        > li {
            @apply mb-3;
        }
    }

    > ol {
        @apply list-decimal;

        ol {
            @apply list-lower-alpha;
        }
    }

    ul {
        @apply list-disc;
    }
}
