.formeo {
    .f-field-group > label {
        font-weight: 600;
        display: block;
    }

    /*
    // todo: figure out how to display true/false and yes/no side by side
    // maybe > :first-child:not(:last-child) + :last-child or something
    .f-field-group .f-field-group {
        columns: 2;
        width: auto;
        display: inline-block;
    }
    */

    .f-addon:focus,
    &.formeo-editor .field-edit :focus[contenteditable],
    input:focus,
    select:focus,
    textarea:focus {
        --tw-ring-color: #a7d8ab;//
        // border-color: #a7d8ab;
    }


    input[type="checkbox"], input[type="radio"] {
        padding: .6em;
        color: #387b46;
    }
}

// Trailer Condition Report
// #f-2fceb5b2-5fb4-4eaf-a46d-7e90d067fc12 .f-field-group .f-field-group {
.trailer-condition-number-chooser {
    display: grid;
    grid-template-columns: 60px 120px 60px 120px 60px 60px;// repeat(6, 1fr);
    grid-template-rows: repeat(4, auto); //1fr
    grid-column-gap: 0px;
    grid-row-gap: 0px;


    .f-checkbox:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
    .f-checkbox:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
    .f-checkbox:nth-child(3) { grid-area: 2 / 1 / 3 / 2; }
    .f-checkbox:nth-child(4) { grid-area: 2 / 2 / 3 / 3; }
    .f-checkbox:nth-child(5) { grid-area: 1 / 3 / 2 / 4; }
    .f-checkbox:nth-child(6) { grid-area: 1 / 4 / 2 / 5; }
    .f-checkbox:nth-child(7) { grid-area: 2 / 3 / 3 / 4; }
    .f-checkbox:nth-child(8) { grid-area: 2 / 4 / 3 / 5; }
    .f-checkbox:nth-child(9) { grid-area: 1 / 5 / 2 / 6; }
    .f-checkbox:nth-child(10) { grid-area: 1 / 6 / 2 / 7; }
    .f-checkbox:nth-child(11) { grid-area: 2 / 5 / 3 / 6; }
    .f-checkbox:nth-child(12) { grid-area: 2 / 6 / 3 / 7; }
    .f-checkbox:nth-child(13) { grid-area: 3 / 1 / 4 / 2; }
    .f-checkbox:nth-child(14) { grid-area: 3 / 2 / 4 / 3; }
    .f-checkbox:nth-child(15) { grid-area: 4 / 1 / 5 / 2; }
    .f-checkbox:nth-child(16) { grid-area: 4 / 2 / 5 / 3; }
    .f-checkbox:nth-child(17) { grid-area: 3 / 3 / 4 / 4; }
    .f-checkbox:nth-child(18) { grid-area: 3 / 4 / 4 / 5; }
    .f-checkbox:nth-child(19) { grid-area: 4 / 3 / 5 / 4; }
    .f-checkbox:nth-child(20) { grid-area: 4 / 4 / 5 / 5; }
    .f-checkbox:nth-child(21) { grid-area: 3 / 5 / 4 / 6; }
    .f-checkbox:nth-child(22) { grid-area: 3 / 6 / 4 / 7; }
    .f-checkbox:nth-child(23) { grid-area: 4 / 5 / 5 / 6; }
    .f-checkbox:nth-child(24) { grid-area: 4 / 6 / 5 / 7; }

    .f-checkbox:nth-child(13), .f-checkbox:nth-child(14), .f-checkbox:nth-child(17), .f-checkbox:nth-child(18), .f-checkbox:nth-child(21), .f-checkbox:nth-child(22) {
        padding-top: 2em;
    }
}

.two-columns {
    column-count: 2;
}
